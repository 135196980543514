// Amount of customer's active orders on orders-history page
export const MAX_ACTIVE_ORDERS = 10

export const DEFAULT_ORDER_TABLE_SIZE = 5

export const MAX_ORDERS_REQ_LIMIT = 20

export const ORDER_STATUS = {
  CREATED: 'created',
  NEW: 'new',
  OPEN: 'open',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  REPLACED: 'replaced',
  FAILED: 'failed',
} as const

export const ORDER_UI_STATUS = {
  PREPARING_FOR_DISPATCH: 'Preparing for Dispatch',
  COMPLETED: 'Completed',
  PROCESSED: 'Order processed',
  PLACED: 'Order placed',
  CANCELLED: 'Cancelled',
  REFUNDED: 'Refunded',
  PARTIALLY_REFUNDED: 'Partially Refunded',
} as const

export const ORDER_REFUND_STATUS = {
  NOT_REFUNDED: 0,
  REFUNDED: 1,
  PARTIAL_REFUND: 2,
} as const

export const ORDER_DELIVERY_STATUS = {
  PLACED: 'PLACED',
  PROCESSED: 'PROCESSED',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
} as const
