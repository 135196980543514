import "app/components/header/styles.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/components/header/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W25KjIBB9z1dQtS/xgaloLpOYl/2TKUSibAi4gKOTrfz7IsSsGM2lZqZqn6zC092nu083vLyF+4jteTSLc/FOJPgzAQALJmQM3pGcQvgWrqvXVVJFm2Br/mlSa5gSLCTSVPAYlDwlklFOtpPT5KV1F8Zc6Gm8o1JpiHPK0sC6ToQ0cMjIzhy7MFoirgokCdeei8ga2L/UhUKMgWg2O6iGyBFSE7mOQehZzV0GpVSN70JQrols8AnC+0wKQxcO5zcPPEeLYUddRKdkz3pfWqud4BpWhGa57hvMf/sGq7G8hsOhnvXXdvfVOkqpKhj6iMGOkbqxbr4wpZJgZyxF1RwjRjMOqSYHFQNMWuIFSlPKM6uFPp0s6CK0KPqAxAMkQmtxGMJ0ST/TrtC3XX+q+utO5FstV8FDQj1zywlqRgnnBO9FqWFSmiJwFyo2vUEJI+l4x8PjSLQfS4wXycbLYGP9oKIgyMwjJjHgotEEAKUyHBRhpuf/DqvcdBuaoXbISqJie2uWBwpr66TokVxVKQ+2d+ooLKJRLcyHEbXL3S0jiVJaqj5m8SsYb7B8qFNtiW2YK/9Zl8NwmEUX4kTuVmdLeVbUHYSZk1u/3aDZs25r762GUaEM1MTzO6TBi7gAEEYeVH/0Fzj6EqlddhPlVgftinpGguftYgie6/i/aPK6Fd4demsrfVaN3UY9e/3dcb/su/f0c1HL7GW1HLyGn7hbVdB7Mzxt+/NAUoqAwpIQDhBPwfRAOaxoqvNGMNGiqN2Lp5OQfRIx5L+I+vMtm6j9CfZGfATRXobS0e5b+senO8wu/5JvJRmOsBwU50PL8jQ5/QX5BoCF1AoAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var checkoutButton = '_1k2lkn29';
export var linkUtilityMenu = '_1k2lkn20';
export var megaMenuInternalItem = '_1k2lkn24';
export var megaMenuInternalItemActive = '_1k2lkn23';
export var megaMenuInternalItemText = '_1k2lkn25';
export var megaMenuListItem = '_1k2lkn21';
export var megaMenuSection = '_1k2lkn22';
export var mobileNavigationMenuBackLink = '_1k2lkn27';
export var mobileNavigationMenuListItem = '_1k2lkn28';
export var mobileNavigationMenuListItemRoot = '_1k2lkn26';
export var viewBasketButton = '_1k2lkn2a';
export var viewBasketContent = '_1k2lkn2b';