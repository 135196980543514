import {
  addMinutes,
  isAfter,
  isBefore,
  subMinutes,
  addHours,
  subDays,
  setHours,
  setMinutes,
  addDays,
  isToday,
} from 'date-fns'
import {StoreModel} from './utils'
import {CommerceAPI, ShopperBaskets, ShopperCustomers} from 'commerce-api'
import {Status, StatusMap} from '../types/commerce'
import {RootStore} from './RootStore'
import {
  action,
  computed,
  flow,
  flowResult,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import {
  MAX_ACTIVE_ORDERS,
  ORDER_DELIVERY_STATUS,
  ORDER_REFUND_STATUS,
  ORDER_STATUS,
  ORDER_UI_STATUS,
} from '../utils/constants'
import {
  OrderRefundStatus,
  OrderStatus,
  OrderTracking,
  OrderTrackingResponse,
} from '../types/store/order'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {DateUtils} from '../utils/date-utils'

type EditOrderInitResult = {redirect: string, error: Error | string} | null
type ReorderOrderInitResult = {redirect: string} | null

export class OrderStore implements StoreModel {
  api: CommerceAPI
  editingOrder: ShopperBaskets.Order | null
  reorderOrder: ShopperBaskets.Order | null
  status: Status
  rootStore: RootStore
  editMode: boolean
  editModeMergeCurrentBasketItems: boolean
  reorderMode: boolean
  reorderModeMergeCurrentBasketItems: boolean
  editOrderDurationExpired: boolean
  recentOrder: ShopperCustomers.Order | null
  populateOrderFromRecentOrder: boolean

  constructor(rootStore: RootStore, initialState = {editingOrder: null, status: StatusMap.IDLE}) {
    this.editingOrder = initialState?.editingOrder
    this.status = initialState?.status
    this.editMode = false
    this.editModeMergeCurrentBasketItems = false
    this.reorderMode = false
    this.reorderModeMergeCurrentBasketItems = false
    this.reorderOrder = null
    this.recentOrder = null
    this.editOrderDurationExpired = false
    this.populateOrderFromRecentOrder = false

    makeObservable(this, {
      status: observable,
      editOrderDurationExpired: observable,
      editingOrder: observable,
      editModeMergeCurrentBasketItems: observable,
      editMode: observable,
      reorderOrder: observable,
      reorderMode: observable,
      reorderModeMergeCurrentBasketItems: observable,
      isPaymentRefunding: computed,
      isPaymentRequired: computed,
      isRequestProcessing: computed,
      orderEditProductItemsDelta: computed,
      orderEditDeliverySlotChanged: computed,
      refundPaymentMethodsDetails: computed,
      orderEditRemovedProducts: computed,
      orderEditOrderTotal: computed,
      orderEditOrderTotalWithoutBonusCard: computed,
      setEditModeMergeCurrentBasketItems: action,
      setReorderModeMergeCurrentBasketItems: action,
      setPopulateOrderFromRecentOrder: action.bound,
      handleIsOrderCanBeShowedAsActive: action.bound,
      validateActiveOrderUIStatus: action.bound,
      validateActiveOrderRefundStatus: action.bound,
      getOrderDeliveryStatus: action.bound,
      isMustBeDelivered: action.bound,
      isOrderEditAccessExpired: action.bound,
      getOrderEditAvailabilityExpiryTimeISO: action.bound,
      handleOrderReorderPermissions: action.bound,
      finishReorder: action.bound,
      getActiveOrders: flow.bound,
      getOrder: flow.bound,
      editOrder: flow.bound,
      prepareOrderEdit: flow.bound,
      initOrderEdit: flow.bound,
      endOrderEdit: flow.bound,
      handleOrderEditPermissions: flow.bound,
      getOrderTrackingData: flow.bound,
      reorder: flow.bound,
      initReorder: flow.bound,
      recentOrder: observable,
      setRecentOrder:flow.bound,
      populateOrderFromRecentOrder: observable
    })

    this.api = rootStore.api
    this.rootStore = rootStore

    reaction(
      () => this.rootStore.customerStore.customerInfo?.c_recentOrderId,
      async () => {
        if (!this.rootStore.customerStore.customerInfo?.c_recentOrderId) return
        await this.setRecentOrder()
      },
    )

    reaction(
      () => this.rootStore.basketStore?.basket?.basketId,
      async () => {
        const {basketStore, globalStore} = this.rootStore
        const {basket} = basketStore

        if (!basketStore.loaded) return

        // Don't go further if basket wasn't created for order edit mode.
        if (!basket?.c_orderNoBeingEdited && !basket?.c_orderEditModeInitiatedTime) return

        if (this.editMode) return

        if (!globalStore.isCustomSitePreferenceSet('orderEditModeDurationInMinutes')) {
          await globalStore.getCustomSitePreferenceById('orderEditModeDurationInMinutes')
        }

        const durationMinutes = Number(
          globalStore.customSitePreferences['orderEditModeDurationInMinutes'] ?? 0
        )

        if (!durationMinutes) {
          await basketStore.retrieveParkedCartBack()
          runInAction(() => (this.editOrderDurationExpired = true))
          return
        }

        const editModeExpirationTime = addMinutes(
          new Date(basket.c_orderEditModeInitiatedTime),
          durationMinutes
        )

        const isExpired = isAfter(new Date(), editModeExpirationTime)

        if (isExpired) {
          basketStore.retrieveParkedCartBack()
          runInAction(() => (this.editOrderDurationExpired = true))
          return
        }

        const orderToEdit = await this.getOrder(basket.c_orderNoBeingEdited)
        const editAllowed = await this.handleOrderEditPermissions(orderToEdit)
        const orderHasValidStatus = this.handleValidOrderStatusEditPermission(orderToEdit)

        if (orderHasValidStatus && editAllowed) {
          this.editingOrder = orderToEdit
          this.editMode = true
        } else {
          basketStore.retrieveParkedCartBack()
          runInAction(() => (this.editOrderDurationExpired = true))
        }
      }
    )
  }

  get asJson() {
    return {
      editingOrder: this.editingOrder,
    }
  }

  get isRequestProcessing() {
    return this.status === StatusMap.PENDING
  }

  get orderEditProductItemsDelta() {
    if (!this.editingOrder || !this.editMode) return {}

    const delta: Record<string, {deltaQty?: number; recentlyAdded?: boolean}> = {}
    const {basketStore} = this.rootStore

    for (const basketItem of basketStore.basket?.productItems || []) {
      const {productId, quantity} = basketItem

      if (!productId) continue

      const originalOrderProduct = this.editingOrder.productItems?.find(
        (productItem) => productItem.productId === productId
      )

      if (!originalOrderProduct) {
        delta[productId] = {
          recentlyAdded: true,
        }
      } else if (
        originalOrderProduct &&
        (quantity || 0) - (originalOrderProduct.quantity || 0) !== 0
      ) {
        delta[productId] = {
          deltaQty: (quantity || 0) - (originalOrderProduct.quantity || 0),
        }
      }
    }

    return delta
  }

  get orderEditOrderTotal() {
    if (!this.editMode) return null

    const orderTotal =
      (this.rootStore.basketStore?.basket?.orderEditBalanceWithoutBC || 0)

    return Number(orderTotal.toFixed(2))
  }

  get orderEditOrderTotalWithoutBonusCard() {
    if (!this.editMode) return null

    const orderTotal =
      (this.rootStore.basketStore?.basket?.orderEditBalanceWithoutBC || this.orderEditOrderTotal)

    return Number(orderTotal.toFixed(2))
  }

  get orderEditRemovedProducts() {
    if (!this.editingOrder || !this.editMode) return []

    const {basketStore} = this.rootStore
    const {productItems = []} = this.editingOrder
    const removedProductsCollection = []

    for (const productItem of productItems) {
      const productInEditCart = basketStore.basket?.productItems?.some(
        ({productId}) => productId === productItem.productId
      )
      if (productInEditCart) continue

      removedProductsCollection.push(productItem)
    }

    return removedProductsCollection
  }

  get orderEditDeliverySlotChanged() {
    if (!this.editingOrder || !this.editMode) return false

    const {basketStore} = this.rootStore

    return basketStore.basket?.c_deliverySlotId !== this.editingOrder.c_deliverySlotId
  }

  get isPaymentRefunding() {
    return (
      this.editMode &&
      (this.rootStore.basketStore?.orderTotal || 0) - (this.editingOrder?.orderTotal || 0) < 0
    )
  }

  get isPaymentRequired() {
    return (
      this.editMode ? (this.rootStore.basketStore.basket?.orderEditBalanceWithoutBC || 0) > 0 : true)
  }

  get refundPaymentMethodsDetails() {
    if (!this.editingOrder || !this.isPaymentRefunding) return null

    const {basketStore} = this.rootStore

    let totalRefundAmount = (this.editingOrder.orderTotal || 0) - (basketStore.orderTotal || 0)

    const details = []

    const {paymentInstruments} = this.editingOrder

    const groupedInstruments = Object.values(
      this.groupPaymentInstrumentsByMethodId(paymentInstruments)
    )

    const sortedInstruments = [...(groupedInstruments || [])].sort((_, next) =>
      next.paymentMethodId === 'BONUS_CARD' ? 1 : -1
    )

    for (const paymentInstrument of sortedInstruments) {
      const refundAmount =
        totalRefundAmount < (paymentInstrument.amount || 0)
          ? totalRefundAmount
          : paymentInstrument.amount || 0

      details.push({paymentMethodId: paymentInstrument.paymentMethodId!, refundAmount})
      totalRefundAmount -= refundAmount
    }

    return details.length ? details : null
  }

  setEditModeMergeCurrentBasketItems(submitMerging: boolean) {
    this.editModeMergeCurrentBasketItems = submitMerging
  }

  setReorderModeMergeCurrentBasketItems(submitMerging: boolean) {
    this.reorderModeMergeCurrentBasketItems = submitMerging
  }

  setPopulateOrderFromRecentOrder(useRecentOrder: boolean) {
    this.populateOrderFromRecentOrder = useRecentOrder
  }

  *setRecentOrder(order?: ShopperCustomers.Order) {
    const orderNo = this.rootStore.customerStore.customerInfo?.c_recentOrderId
    if (!orderNo && !order) {
      return
    }
    try {
      if (order) {
        this.recentOrder = order
      }
      else if (orderNo && orderNo != this.recentOrder?.orderNo) {
        const recentOrder: ShopperCustomers.Order = yield this.getOrder(orderNo)
        this.recentOrder = recentOrder
      }
    } catch (error) {
      console.error('Error retrieving recent order')
    }
    return
  }

  *getOrder(orderNo: string) {
    try {
      this.status = StatusMap.PENDING
      const result: ShopperCustomers.Order = yield this.api.shopperOrders.getOrder({
        parameters: {
          orderNo: orderNo,
        },
      })
      this.status = StatusMap.DONE

      const {productStore} = this.rootStore

      if (result.productItems?.length) {
        const ids = result.productItems
          .map((product) => product.productId)
          .filter((id): id is string => !!id)

        yield productStore.fetchProducts(ids, true)
      }

      return result
    } catch (error) {
      this.status = StatusMap.ERROR
      throw error
    }
  }

  *editOrder(orderNo: string) {
    const action: EditOrderInitResult = yield this.prepareOrderEdit(orderNo)
    return action
  }

  *prepareOrderEdit(orderNo: string) {
    try {
      if (!orderNo) return null

      this.status = StatusMap.PENDING


      const order: ShopperBaskets.Order = yield this.getOrder(orderNo)
      const editAccessExpired: boolean = yield this.isOrderEditAccessExpired(order)

      if (!this.handleValidOrderStatusEditPermission(order) || editAccessExpired) {
        return null
      }

      const {basketStore} = this.rootStore

      this.editingOrder = order

      // If customer has added products in current basket, open merge basket products UI modal
      // next flow step is handled inside Merge Baskets Modal component.

      if (basketStore.count) {
        basketStore.basketMergeType = 'edit'
        basketStore.initMergingUI = true
        return null
      }

      const initResult: EditOrderInitResult | null = yield flowResult(this.initOrderEdit(orderNo))

      return initResult
    } catch (error) {
      this.editMode = false
      this.status = StatusMap.ERROR
      return null
    }
  }

  *initOrderEdit(isMerge = false, orderNo = this.editingOrder?.orderNo | null) {
    try {
      if (!orderNo) return null
      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/DeliveryOrder-EditOrder?orderid=${orderNo}&ismerge=${isMerge}`,
        {
          method: 'POST',
          credentials: 'include',
        }
      )

      const json: {
        success?: boolean,
        basket: ShopperBaskets.Basket
        errorMessage?: string
      } = yield res.json()

      if (!res.ok || !json?.success) {
        const errorMessage = json?.errorMessage || 'Error during order edit'
        // If edit limit reached, return error
        if (errorMessage === 'edit_limit_reached') return {error: errorMessage}
        throw new Error(`${errorMessage}: ${orderNo}`)
      }

      this.editMode = true

      this.rootStore.basketStore.getOrCreateBasket(json.basket);

      // UI route to redirect user after init completed
      return {redirect: '/basket'}
    } catch (error) {
      this.editMode = false
      this.status = StatusMap.ERROR
      return error
    }
  }

  *endOrderEdit() {

    if (!this.editingOrder) return null
    const {orderNo} = this.editingOrder
            
    const res: Response = yield fetch(
      `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/DeliveryOrder-StopEditingOrder?&orderid=${orderNo}`,
      {
        method: 'POST',
        credentials: 'include',
      }
    )

    const json: {success?: boolean, basket: ShopperBaskets.Basket} = yield res.json()

    if (!res.ok || !json?.success) {
      throw new Error('Error during order edit: ' + orderNo)
    }

    this.editMode = false
    this.editingOrder = null

    this.rootStore.basketStore.getOrCreateBasket(json.basket);

    // UI route to redirect user after init completed
    return {redirect: '/basket'}

  }

  *reorder(orderNo: string) {
    try {
      if (!orderNo) return null

      this.status = StatusMap.PENDING

      const order: ShopperBaskets.Order = yield this.getOrder(orderNo)

      const allowed = this.handleOrderReorderPermissions(order)

      if (!allowed) return null

      const {basketStore} = this.rootStore
      this.reorderMode = true
      this.reorderOrder = order
      basketStore.basketMergeType = ''

      // If customer has added products in current basket, open merge basket products UI modal
      // next flow step is handled inside Merge Baskets Modal component.
      if (basketStore.hasItems) {
        basketStore.basketMergeType = 'reorder'
        basketStore.initMergingUI = true
        return null
      }

      const initResult: ReorderOrderInitResult = yield flowResult(this.initReorder())

      return initResult
    } catch (error) {
      this.status = StatusMap.ERROR
      console.error(error)
      this.finishReorder()
      return null
    }
  }

  *initReorder(isMerge: boolean) {
    if (!this.reorderOrder) return null

    try {

      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/DeliveryOrder-ReOrder?&orderid=${this.reorderOrder.orderNo}&ismerge=${isMerge}`,
        {
          method: 'POST',
          credentials: 'include',
        }
      )

      const json: {success?: boolean, basket: ShopperBaskets.Basket} = yield res.json()

      if (!res.ok || !json?.success) {
        throw new Error('Error during order reorder: ' + this.reorderOrder.orderNo)
      }

      this.rootStore.basketStore.getOrCreateBasket(json.basket);

      this.rootStore.orderStore.recentOrder = null;

      // UI route to redirect user after init completed
      return {redirect: '/basket'}
    } catch (error) {
      console.log(error)
      return null
    } finally {
      this.finishReorder()
    }
  }

  finishReorder() {
    this.reorderOrder = null
    this.reorderModeMergeCurrentBasketItems = false
    this.reorderMode = false
  }

  *handleOrderEditPermissions(order: ShopperBaskets.Order) {
    if (!order) return false

    const permissionsCustomPreferenceProperties = [
      'deliveryEditOrderExpirationTime',
      'deliveryEditOrderMaxDaysAfterAuth',
    ]
    const {globalStore} = this.rootStore

    const fetchPrefIds = permissionsCustomPreferenceProperties.filter(
      (customPrefId) => !globalStore.isCustomSitePreferenceSet(customPrefId)
    )

    if (fetchPrefIds.length) {
      yield globalStore.getCustomSitePreferencesByIds(fetchPrefIds)
    }

    let allowed = true

    // Checking  if delivery window time is closed or not.
    if (
      globalStore.isCustomSitePreferenceSet('deliveryEditOrderExpirationTime') &&
      typeof globalStore.customSitePreferences['deliveryEditOrderExpirationTime'] === 'number'
    ) {
      const {c_deliverySlotId, c_windowStartTime} = order

      if (!c_deliverySlotId || !c_windowStartTime) {
        allowed = false
      }

      //rolling back 1 day and set midnight
      const deliveryStartWindowDate = setHours(subDays(new Date(c_windowStartTime), 1), 24)

      const closedDeliveryTime = addHours(
        deliveryStartWindowDate,
        globalStore.customSitePreferences['deliveryEditOrderExpirationTime']
      )

      if (!closedDeliveryTime || isBefore(closedDeliveryTime, new Date())) {
        allowed = false
      }
    }

    // Checking if first auth payment date permission expired.
    if (
      globalStore.isCustomSitePreferenceSet('deliveryEditOrderMaxDaysAfterAuth') &&
      typeof globalStore.customSitePreferences['deliveryEditOrderMaxDaysAfterAuth'] === 'number'
    ) {
      const {paymentInstruments} = order

      const [firstAuthPaymentInstrument] = [...(paymentInstruments || [])].sort(
        (prev, next) =>
          new Date(prev.c_creationDate).getTime() - new Date(next.c_creationDate).getTime()
      )

      if (firstAuthPaymentInstrument && firstAuthPaymentInstrument.c_status === 'authorized') {
        const {c_creationDate} = firstAuthPaymentInstrument

        const firstAuthTransactionDate = new Date(c_creationDate)

        // Adding days
        const expDate = addDays(
          firstAuthTransactionDate,
          globalStore.customSitePreferences['deliveryEditOrderMaxDaysAfterAuth']
        )

        if (!expDate || isBefore(expDate, new Date())) {
          allowed = false
        }
      }
    }

    return allowed
  }

  handleOrderReorderPermissions(order: ShopperBaskets.Order) {
    if (!order || this.editMode) return false

    let allowed = true

    if (order.c_isTopUp) {
      allowed = false
    }

    if (order.c_isReorderDisabled) {
      allowed = false
    }

    const allowedStatuses = [ORDER_STATUS.CANCELLED, ORDER_STATUS.COMPLETED] as OrderStatus[]
    const status = order.status as OrderStatus
    const refunded = order.c_data.fulfilment_step === 5
    const partialRefunded = order.c_data.fulfilment_step === 6

    if (!allowedStatuses.includes(status) && !refunded && !partialRefunded) {
      allowed = false
    }

    return allowed
  }

  *getOrderTrackingData(orderNo: string) {
    try {
      const response: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Delivery-GetDeliveryTrackingInfo?orderid=${orderNo}`
      )

      if (!response.ok) {
        throw new Error('Error during fetching Delivery Order Tracking information for: ' + orderNo)
      }

      const json: OrderTrackingResponse = yield response.json()
      return json
    } catch (error) {
      console.log(error)
      return {success: false} as OrderTrackingResponse
    }
  }

  getCustomBookingSlotAttributesFromOrder(order: ShopperBaskets.Order) {
    const extracted: Record<string, unknown> = {}
    const orderSlotAttributes = [
      'c_deliveryPostalCode',
      'c_deliverySlotId',
      'c_deliveryStoreGroup',
      'c_deliveryStoreId',
      'c_isCarrierBagAdded',
      'c_slotExpiryTime',
      'c_slotReservationId',
      'c_windowEndTime',
      'c_windowStartTime',
    ]

    for (const orderSlotAttribute of orderSlotAttributes) {
      if (orderSlotAttribute in order) {
        extracted[orderSlotAttribute] = order[orderSlotAttribute]
      }
    }

    return extracted
  }

  handleValidOrderStatusEditPermission(order: ShopperBaskets.Order) {
    if (!order) return false

    return order.status !== 'cancelled'
  }

  groupPaymentInstrumentsByMethodId(
    paymentInstruments: ShopperBaskets.Order['paymentInstruments']
  ) {
    if (!paymentInstruments?.length) return []

    return paymentInstruments.reduce((grouped, currentPaymentInstrument) => {
      const methodId = currentPaymentInstrument.paymentMethodId!
      if (grouped[methodId]) {
        grouped[methodId] = {
          ...grouped[methodId],
          amount: grouped[methodId].amount + (currentPaymentInstrument.amount || 0),
        }
      } else {
        grouped[methodId] = {...currentPaymentInstrument}
      }

      return grouped
    }, [])
  }

  *getActiveOrders(orders: ShopperCustomers.Order[]) {
    const nonFailedOrders = orders?.filter((order) => order.status !== ORDER_STATUS.FAILED)

    if (!nonFailedOrders?.length) return []

    let activeOrders: ShopperCustomers.Order[] = nonFailedOrders.filter(
      (order) => order.status !== ORDER_STATUS.CANCELLED && order.status !== ORDER_STATUS.REPLACED
    )

    const currentOrdersPeriod =
      this.rootStore.globalStore.customSitePreferences['currentOrdersPeriod']
    const currentDate = setMinutes(setHours(new Date(), 0), 0)

    const activeOrdersDate =
      typeof currentOrdersPeriod === 'number' ? subDays(currentDate, currentOrdersPeriod) : null

    if (activeOrdersDate) {
      activeOrders = activeOrders.filter(
        (order) => new Date(order.c_windowStartTime) > new Date(activeOrdersDate)
      )
    }

    activeOrders = activeOrders.filter((order) =>
      this.rootStore.orderStore.handleIsOrderCanBeShowedAsActive(order)
    )

    activeOrders = activeOrders.slice(0, MAX_ACTIVE_ORDERS)

    for (const activeOrder of activeOrders) {
      const isDeliveryDayToday = isToday(new Date(activeOrder.c_windowStartTime))
      const orderInternalStatus = activeOrder.status === ORDER_STATUS.COMPLETED

      if (activeOrder.orderNo && orderInternalStatus && isDeliveryDayToday) {
        const trackingData: OrderTrackingResponse = yield this.getOrderTrackingData(
          activeOrder.orderNo
        )

        if (trackingData.success) {
          activeOrder.c_trackingData = {...trackingData.data, orderNo: activeOrder.orderNo}
        }
      }
    }

    return activeOrders
  }

  handleIsOrderCanBeShowedAsActive(order: ShopperCustomers.Order) {
    if (order.c_isTopUp) {
      return false
    }

    return this.validateActiveOrderUIStatus(order) && this.validateActiveOrderRefundStatus(order)
  }

  validateActiveOrderUIStatus(order: ShopperCustomers.Order) {
    const orderUIStatus = order.c_data.fulfilment_status ?? ORDER_UI_STATUS.PLACED

    return [
      ORDER_UI_STATUS.PLACED,
      ORDER_UI_STATUS.PREPARING_FOR_DISPATCH,
      ORDER_UI_STATUS.COMPLETED,
    ].includes(orderUIStatus)
  }

  validateActiveOrderRefundStatus(order: ShopperCustomers.Order) {
    let refundStatus: OrderRefundStatus = ORDER_REFUND_STATUS.NOT_REFUNDED

    if (order.c_pickedStatus) {
      if (order.c_refunded || Number(order.c_amountPaid) === 0) {
        refundStatus = ORDER_REFUND_STATUS.REFUNDED
      }
      // @todo - Handle PARTIAL_REFUND
    }
    return refundStatus === ORDER_REFUND_STATUS.NOT_REFUNDED
  }

  getOrderDeliveryStatus(order: ShopperCustomers.Order) {
    let status

    if (order?.c_data?.fulfilmentStatus?.toUpperCase() === ORDER_DELIVERY_STATUS.CANCELLED) {
      status = ORDER_DELIVERY_STATUS.CANCELLED
    } else if (this.isMustBeDelivered(order)) {
      status = ORDER_DELIVERY_STATUS.COMPLETED
    } else if (this.isOrderEditAccessExpired(order)) {
      status = ORDER_DELIVERY_STATUS.PROCESSED
    } else {
      status = ORDER_DELIVERY_STATUS.PLACED
    }

    if (!order?.c_trackingData || !order.c_trackingData.deliveryStatus) {
      return status
    }

    const statusKey: 'FAILED' | 'COMPLETED' | 'OUT_FOR_DELIVERY' =
      order?.c_trackingData?.deliveryStatus?.toUpperCase()?.split(' ')?.join('_')

    if (ORDER_DELIVERY_STATUS[statusKey]) status = ORDER_DELIVERY_STATUS[statusKey]
    return status
  }

  isMustBeDelivered(order: ShopperCustomers.Order) {
    if (!order.c_windowEndTime || !order.c_pickedStatus) return false

    const currentDate = new Date()
    const deliveryDate = new Date(order.c_windowEndTime)

    return isAfter(currentDate, deliveryDate)
  }

  isOrderEditAccessExpired(order: ShopperCustomers.Order | ShopperBaskets.Order) {
    if (order.c_isTopUp) return true

    const expiryDate = this.getOrderEditAvailabilityExpiryTimeISO(order)

    let expired = expiryDate == null

    if (!expired && expiryDate) {
      const {c_deliverySlotId, c_windowStartTime} = order

      if (!c_deliverySlotId || !c_windowStartTime) {
        expired = true
      }

      if (isBefore(new Date(expiryDate), new Date())) {
        expired = true
      }
    }

    return expired
  }

  getOrderEditAvailabilityExpiryTimeISO(order: ShopperCustomers.Order | ShopperBaskets.Order) {
    const {globalStore} = this.rootStore

    let editExpiryDate: Date

    if (order.c_sameDayClassifier && order.c_sameDayHardCutOff) {
      editExpiryDate = new Date(order.c_sameDayHardCutOff)
    } else {
      const {c_deliverySlotId, c_windowStartTime} = order

      const deliveryStartWindowDate = new Date(c_windowStartTime)

      if (
        !c_deliverySlotId ||
        !c_windowStartTime ||
        !DateUtils.validateDate(deliveryStartWindowDate)
      ) {
        return null
      }

      editExpiryDate = subDays(deliveryStartWindowDate, 1)

      editExpiryDate = setHours(editExpiryDate, 24)
      editExpiryDate = setMinutes(editExpiryDate, 0)

      if (
        globalStore.customSitePreferences['deliveryEditOrderExpirationTime'] &&
        typeof globalStore.customSitePreferences['deliveryEditOrderExpirationTime'] === 'number'
      )
        editExpiryDate = addHours(
          editExpiryDate,
          globalStore.customSitePreferences['deliveryEditOrderExpirationTime']
        )
    }

    return DateUtils.validateDate(editExpiryDate) ? editExpiryDate?.toISOString() : null
  }

  isDeliveryTrackingMapDisplayed(order: ShopperCustomers.Order & {c_trackingData?: OrderTracking}) {
    if (!order.c_trackingData) return false

    const {globalStore} = this.rootStore
    const data = order?.c_trackingData

    let inStartRange = false
    let inEndRange = false

    if (
      globalStore.isCustomSitePreferenceSet('numberOfStops') &&
      typeof globalStore.customSitePreferences['numberOfStops'] === 'number'
    ) {
      if (data.numberOfStops > globalStore.customSitePreferences['numberOfStops']) return false
    }

    const {c_windowStartTime} = order
    let showMapStartDate: Date = DateUtils.utcToZonedDate(c_windowStartTime)

    if (DateUtils.validateDate(showMapStartDate)) {
      if (
        globalStore.isCustomSitePreferenceSet('showMapMinutesBeforeDeliverySlotStart') &&
        typeof globalStore.customSitePreferences['showMapMinutesBeforeDeliverySlotStart'] ===
          'number'
      ) {
        showMapStartDate = subMinutes(
          showMapStartDate,
          globalStore.customSitePreferences['showMapMinutesBeforeDeliverySlotStart']
        )
      }

      const currentDate = DateUtils.current()

      if (isAfter(currentDate, showMapStartDate)) {
        inStartRange = true
      }
    }

    const {c_windowEndTime} = order
    let showMapEndDate: Date = DateUtils.utcToZonedDate(c_windowEndTime)

    if (DateUtils.validateDate(showMapEndDate)) {
      if (
        globalStore.isCustomSitePreferenceSet('showMapMinutesAfterDeliverySlotEnd') &&
        typeof globalStore.customSitePreferences['showMapMinutesAfterDeliverySlotEnd'] === 'number'
      ) {
        showMapEndDate = addMinutes(
          showMapEndDate,
          globalStore.customSitePreferences['showMapMinutesAfterDeliverySlotEnd']
        )

        const currentDate = DateUtils.current()

        if (isBefore(currentDate, showMapEndDate)) {
          inEndRange = true
        }
      }
    }

    return inStartRange && inEndRange
  }

  handleOrderReorderAvailability(order: ShopperCustomers.Order) {
    const orderUIStatus = order.c_data?.fulfilment_status || order.c_data?.fulfilmentStatus

    return (
      [
        ORDER_UI_STATUS.COMPLETED,
        ORDER_UI_STATUS.CANCELLED,
        ORDER_UI_STATUS.REFUNDED,
        ORDER_UI_STATUS.PARTIALLY_REFUNDED,
      ].includes(orderUIStatus) &&
      !order.c_isTopUp &&
      !order.c_isReorderDisabled
    )
  }

  handleOrderEditAvailability(order: ShopperCustomers.Order) {
    return (
      order.status === ORDER_STATUS.CREATED &&
      order.hasOwnProperty('c_pickedStatus') &&
      !order.c_pickedStatus
    )
  }
}
